import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, Container, Grid } from '@mui/material'
import Card from '../components/ourService/Card'
import { products } from './../constants'
const Services = () => {
    return (
        <div>
            <Header2 title={'خدماتنا'} link1={'الصفحة الرئيسية'} link2={'خدماتنا'} />
            <Container sx={{ padding: { md: '50px 0', xs: '25px' } }} >
                <Box>
                    <Grid container spacing={4} >
                        {products.map((product) =>
                            <Grid item md={6} xs={12} key={product?.id} >
                                <Card path={product.imageUrl}
                                    desc={product.desc}
                                    name={product.supName}
                                    route={`/service/${product?.id}`} />
                            </Grid>
                        )}
                        {/* <Grid item md={6} xs={12} >
                            <Card path={D27} desc={'test'} name={'Interfaces'} route={'/Interfaces'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D11} desc={'test'} name={'Hoarding-Signs'} route={'/Hoarding-Signs'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D21} desc={'test'} name={'ACP & Wood Cladding'} route={'/ACP-Wood'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D12} desc={'test'} name={'Various Project'} route={'/Various-Project'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D5} desc={'test'} name={'Entrances & Gates'} route={'/Entrances-Gates'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D4} desc={'test'} name={'ACP Colomns & Awnings'} route={'/ACP-Colomns'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D6} desc={'test'} name={'Kiosks'} route={'/Kiosks'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D7} desc={'test'} name={'Car Vinyl'} route={'/Car-Vinyl'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D10} desc={'test'} name={'Stand'} route={'/Stand'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D9} desc={'test'} name={'Decor'} route={'/decor'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D9} desc={'test'} name={'Decor'} route={'/decor'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D9} desc={'test'} name={'Decor'} route={'/decor'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D9} desc={'test'} name={'Decor'} route={'/decor'} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <Card path={D9} desc={'test'} name={'Decor'} route={'/decor'} />
                        </Grid> */}
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default Services
