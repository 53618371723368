import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import aboutHome from './../../assets/AboutUs/service2.png'

const AboutUs = () => {
    return (
        <div style={{ padding: '40px 0 0 0' }} >
            <Container>
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'end', flexDirection: { md: 'row', xs: 'column' } }} >
                    <Box sx={{
                        width: { md: '50%', xs: '100%' }, padding: { md: 2, xs: 1 },
                        boxShadow: "0px 3px 6px rgba(0, 0, 0,5%)",
                        height: { md: '30vh', xs: '35vh' },
                        background: '#FFF', zIndex: 22,
                        position: { md: 'absolute', xs: 'initial' },
                        left: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        justifyContent: 'center'
                    }} >
                        <Typography variant='h4' >
                            كلين لايت افضل شركة تنظيف منازل 
                        </Typography>
                        <Typography variant='h4' >
                            0534364350
                        </Typography>
                        <Typography>
                            كلين لايت رواد التنظيف المنزلي المتكامل  
                            نمتلك سنوات طويلة من الخبرات في مجال التنظيف المتكامل للمنازل
                            والفيلل والقصور والشركات  بايدي عمالة اسيوية مدرية باحترافيه
                        </Typography>
                    </Box>
                    <Box >
                        <CardMedia
                            component={'img'}
                            style={img}
                            src={aboutHome}
                            sx={{ height: '450px', objectFit: { md: 'fill', xs: 'cover' }, }}
                        />
                    </Box>
                </Box>
            </Container>
        </div>
    )
}
const img = {
    width: '100%',

    borderRadius: '12px',
    backgroundSize: 'cover',
    // boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',

    margin: 'auto',

}
export default AboutUs
