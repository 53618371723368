import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer/Footer";
import ScrollTo from './components/Ui/ScrollToTop';
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import TopNav from "./components/NavBar/TopNav";
import SotialFooter from "./components/glopal/SotialFooter";
function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <div className="App">
      <CssBaseline />
      <TopNav />
      <NavBar />
      <SotialFooter />
      <ScrollToTop />
      <ScrollTo />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/service/:id" element={<Service />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
