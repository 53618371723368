import { Box, Typography } from '@mui/material'
import React from 'react'
import './Header.css'
const Header2 = ({ title, link1, link2 }) => {
    return (
        <div>
            <div className='header'>
                <div className="overlay"></div>
                <Box sx={{ display: 'flex', alignItems: { md: 'center', xs: 'inherit' }, height: '100%', flexDirection: 'column' }} >
                    <Typography sx={{
                        color: "#FFF",
                        fontWeight: 'bold',
                        zIndex: 9,
                        padding: 4
                    }} variant='h3' >
                        {title}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: { md: '125px', xs: '25px' } }} >
                        <Typography sx={{
                            color: "#FFF",
                            fontWeight: 'bold',
                            zIndex: 9,

                        }} variant='h5' >
                            {link1}
                        </Typography>
                        <Typography sx={{
                            color: "#FFF",
                            fontWeight: 'bold',
                            zIndex: 9,
                            mx: 1
                        }} variant='h5' >
                            -
                        </Typography>
                        <Typography sx={{
                            color: "#FFF",
                            fontWeight: 'bold',
                            zIndex: 9,

                        }} variant='h5' >
                            {link2}
                        </Typography>
                    </Box>
                </Box>
            </div>
        </div>
    )
}

export default Header2
