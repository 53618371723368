import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './cardeffect.css'
const CardServ = ({ path, name, desc }) => {
    return (
        <div>
            <Box sx={{
                boxShadow: "0px 3px 6px rgba(0, 0, 0,5%)",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',

            }} >
                <Box>
                    <CardMedia
                        component={'img'}
                        src={path}
                    />
                </Box>
                <Box sx={{
                    // display: 'none',
                    position: 'absolute',
                    bottom: '0',
                    padding: '15px',
                    backgroundColor: 'rgb(255 255 255 / 75%)',
                    width: '100%'
                }} >
                    <Typography>{name}</Typography>
                    <Typography>{desc}</Typography>
                </Box>
            </Box>
        </div>
    )
}

export default CardServ
