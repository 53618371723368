import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import Card from '../components/glopal/Card'
import bg from './../assets/Shape BG.png'
import HowToWork from '../components/HowToWork/HowToWork'
import Sfaq from '../components/Sfaq/Sfaq'
import CardPart from '../components/Partners/CardPart'
import part1 from './../assets/Ellipse 13.png'
import part2 from './../assets/testimonial-2-100x100.png'
const About = () => {
    return (
        <div>
            <Header2 title={'من نحن'} link1={'الصفحة الرئيسية'} link2={'من نحن'} />
            <Container>
                <Box sx={{ padding: '25px 0' }}>
                    <Card
                        path={bg}
                        name={'كلين لايت'}
                        supName={'لخدمات التنظيف المتكامل'}
                        desc={'نحن شركة كلين لايت لخدمات التنظيف المتكامل  متخصصون في التنظيف بمختلف انواعة ,فك وتركيب وعزل الخزانات وابادة الحشرات والقوارض'}
                        supDesc={'لدينا فريق عمل متكامل مدرب علي اعلي مستوي والمكون من خبرات طويلة في مجال التنظيف والرش وتنسيق الحدائق , لدينا احدث المعدات والتي نواظب علي تحديثها بشكل مستمر لمواكبة كل جديد في عالم التنظيف'}
                    />
                    <HowToWork />
                    <Sfaq />
                    <Container>
                        <Box>
                            <Box my={2} >
                                <Typography sx={{ color: '#239698', my: 1 }} variant='h4' >قالو عنا</Typography>
                                <Divider sx={{ backgroundColor: '#FFA53B', borderWidth: '1px', width: '10%' }} />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12} >
                                    <CardPart path={part1} name={'أحمد محمد'} desc={'تعاملت مع شركات نظافة مختلفة, ولكن شركة كلين لايت اثبتت انها افضل شركة تنظيف  وذلك نظرا لدقة مواعيدهم وخدماتهم المتوفقة.'} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <CardPart path={part2} name={'محمد أحمد'} desc={'عانيت كثيرا مع شركات النظافة المختلفة الى ان وجدت شركة كلين لايت المحترفة, وذلك نظرا لمعداتهم وفريق العمل المحترف التي تمتلكه المؤسسة'} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </Container>
        </div>
    )
}

export default About
