import { Box, CardMedia, Container, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import path from './../../assets/Group 976.png'
import path1 from './../../assets/Group 977.png'
import path2 from './../../assets/Group 978.png'
const HowToWork = () => {
    return (
        <div>
            <Container>
                <Box my={2} >
                    <Typography sx={{ color: '#239698', my: 2 }} variant='h4' >كيف نعمل معك؟</Typography>
                    <Divider sx={{ backgroundColor: '#FFA53B', borderWidth: '1px', width: '10%' }} />
                </Box>
                <Grid container spacing={2} sx={{ alignItems: 'flex-start', justifyContent: 'center' }} >
                    <Grid item md={4} xs={12}>
                        <Box>
                            <CardMedia
                                component={'img'}
                                style={img}
                                src={path1}
                                sx={{
                                    width: { md: '75%', xs: '50%' },
                                    position: 'relative',
                                    zIndex: 99,
                                    transition: '0.5s',
                                    '&:hover': {
                                        transform: 'scale(1.05)'
                                    }
                                }}
                            />
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} >التقييم</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box>
                            <CardMedia
                                component={'img'}
                                style={img}
                                src={path}
                                sx={{
                                    width: { md: '75%', xs: '50%' },
                                    position: 'relative',
                                    zIndex: 99,
                                    transition: '0.5s',
                                    '&:hover': {
                                        transform: 'scale(1.05)'
                                    }
                                }}
                            />
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} >التخطيط والتنفيذ</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box>
                            <CardMedia
                                component={'img'}
                                style={img}
                                src={path2}
                                sx={{
                                    width: { md: '75%', xs: '50%' },
                                    position: 'relative',
                                    zIndex: 99,
                                    transition: '0.5s',
                                    '&:hover': {
                                        transform: 'scale(1.05)'
                                    }
                                }}
                            />
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} >المتابعة والصيانة</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
const img = {

    borderRadius: '12px',
    backgroundSize: 'cover',
    objectFit: 'cover',
    margin: 'auto'
}
export default HowToWork
