import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import Title from './../glopal/Title'
import Card from './Card'
import { products } from './../../constants'

const OurServices = () => {
    return (
        <Box sx={{ padding: { md: '40px', xs: '10px' } }} >
            <Container>
                <Box>
                    <Title title={'خدماتنا'} />
                    <Box sx={{ pt: 2 }} >
                        <Grid container spacing={2} >
                            {products.map((product) =>
                                <Grid item md={6} xs={12} key={product?.id} >
                                    <Card path={product.imageUrl}
                                        desc={product.desc}
                                        name={product.supName}
                                        route={`/service/${product?.id}`} />
                                </Grid>
                            )}
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }} >
                            {/* <Button variant="contained"
                                sx={{
                                    backgroundColor: '#FFA53B',
                                    color: '#000',
                                    gap: 2,
                                    fontSize: '1rem',
                                    display: 'flex',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    boxShadow: "0px 0 0 rgba(0, 0, 0)",
                                    // borderRadius: '12px',
                                    m: 2, '&:hover': {
                                        backgroundColor: '#239698',
                                        color: '#FFF',
                                        boxShadow: "0px 3px 6px rgba(0, 0, 0,15%)",
                                    }
                                }} >
                                <Link
                                    to="/Services"
                                    style={{
                                        color: "inherit",
                                        textDecoration: "none",
                                        // fontSize: 18,
                                        // fontWeight: "bold",
                                    }}>
                                    {'اعرض أكثر'}
                                </Link>
                            </Button> */}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default OurServices
