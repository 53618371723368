import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, Container, Typography } from '@mui/material'
// import Form from '../components/Contact/Form'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CardContact from '../components/Contact/CardContact';
const Contact = () => {
    return (
        <div>
            <Header2 title={'اتصل بنا'} link1={'الصفحة الرئيسية'} link2={'اتصل بنا'} />
            <Container sx={{ padding: '50px 10px' }} >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }} >
                    <Typography sx={{ fontWeight: 'bold' }} variant='h5' >متواجدون على مدار الساعة لخدمتكم</Typography>
                    {/* <Form /> */}
                    <Box sx={{}} >
                        <Typography sx={{ color: '#01213D', my: 1 }} variant='h4' >تواصل معنا</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, flexDirection: { md: 'row', xs: 'column' }, gap: 2 }} >
                            <CardContact name={'العنوان'} desc={'السعودية'} >
                                <LocationOnIcon sx={{ color: '#FFA53B', fontSize: 60 }} />
                            </CardContact>
                            <a href="tel:+966534364350" style={{ textDecoration: 'none', color: '#FFF' }} >
                                <CardContact name={'رقم التليفون'} desc={'+966534364350'} >
                                    <PhoneIphoneIcon sx={{ color: '#FFA53B', fontSize: 60 }} />
                                </CardContact>
                            </a>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}
export default Contact
