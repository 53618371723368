import { Box, CardMedia, Container, Divider, Typography } from '@mui/material'
import React from 'react'
import Accordions from '../Accordions'
import effect from './../../assets/11.png'
import path from './../../assets/Group 984.png'
const Sfaq = () => {
    return (
        <div style={{ padding: '25px 0' }} >
            <Container>
                <Box my={2} >
                    <Typography sx={{ color: '#239698', my: 2 }} variant='h5' >بعض الأسئلة المتكررة</Typography>
                    <Divider sx={{ backgroundColor: '#FFA53B', borderWidth: '1px', width: '10%' }} />
                </Box>
                <Box display={"flex"} flexDirection={{ md: "row", xs: "column" }} gap={4} alignItems={"center"} justifyContent={"space-between"} >
                    <Accordions />
                    <Box sx={{ position: 'relative', width: { md: '100%', xs: '100%' } }} >
                        <CardMedia
                            sx={{ position: 'absolute' }}
                            component={'img'}
                            src={effect}
                        />
                        <CardMedia
                            component={'img'}
                            style={img}
                            src={path}
                        />
                        <CardMedia
                            sx={{ position: 'absolute', transform: 'rotate(180deg)', bottom: '-1px' }}
                            component={'img'}
                            src={effect}
                        />
                    </Box>
                </Box>
            </Container>
        </div>
    )
}
const img = {
    width: '100%',
    height: '400px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    objectFit: 'cover',
    margin: 'auto'
}
export default Sfaq
