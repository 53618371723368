import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import bg from './../../assets/Group 1003.png'
// import tm from './../../assets/logo2.png'
// import ta from './../../assets/logo1.png'

const DontWhore = () => {
    return (
        <div style={{ padding: '40px 0' }}  >
            <Container>
                <Box sx={{ py: 2 }} >
                    <Typography sx={{
                        textAlign: 'center',
                        color: '#000',
                        width: '100%',
                        padding: '15px',
                        fontWeight: 'bold',
                        boxShadow: "0px 3px 6px rgba(0, 0, 0,5%)", backgroundColor: '#fff'
                    }} variant='h5' >
                        لا تشيل هم النظافه والصيانه مع مؤسسة كلين لايت
                    </Typography>
                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }} >
                        <Box sx={{ position: 'relative', width: '100%' }} >
                            <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgb(110 237 239 / 15%)' }} ></Box>
                            <CardMedia
                                component={'img'}
                                //   style={img}
                                sx={{ height: { md: '50vh', xs: '75vh' } }}
                                src={bg}
                            />
                        </Box>
                        <Box sx={{ position: 'absolute', padding: { md: 'inherit', xs: '20px' } }} >
                            <Box sx={{ display: 'flex', gap: '10px', color: '#FFF', my: 1, fontWeight: 'bold' }} >
                                <TaskAltIcon sx={{ fontSize: { md: '40px', xs: '30px' } }} />
                                <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }} >منازل  - شقق - مدارس  - مباني - شاليهات - فنادق </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '10px', color: '#FFF', my: 1, fontWeight: 'bold' }} >
                                <TaskAltIcon sx={{ fontSize: { md: '40px', xs: '30px' } }} />
                                <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }} >الأثاث  - الأرضيات- الخزانات  - السجاد - الستائر - المداخل - الدرج </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '10px', color: '#FFF', my: 1, fontWeight: 'bold' }} >
                                <TaskAltIcon sx={{ fontSize: { md: '40px', xs: '30px' } }} />
                                <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }} >أحدث الأجهزه وأفضل مواد النضافه والتعقيم لمكافخة الحشرات</Typography>
                            </Box>
                            {/* <Box sx={{ textAlign: 'center' }} >
                                <Typography sx={{ color: '#FFF', fontWeight: 'bold', fontSize: { md: '2.125rem', xs: '1.5rem' } }} variant='h4' >يوجد لدينا خدمة الدفع أقساط</Typography>
                                <Box sx={{ display: 'flex', mt: '5%', justifyContent: 'center', gap: '24px' }} >
                                    <CardMedia
                                        component={'img'}
                                        style={img}
                                        src={ta}
                                    />
                                    <CardMedia
                                        component={'img'}
                                        style={img}
                                        src={tm}
                                    />
                                </Box>
                            </Box> */}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}
// const img = {
//     width: '100px',
//     // height: '75px',
//     borderRadius: '12px',
//     backgroundSize: 'cover',
//     objectFit: 'fill',
//     // margin: 'auto',

// }
export default DontWhore
