import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
const ContactData = () => {
    return (
        <div>
            <Box>
                <Typography sx={{
                    fontWeight: 'bold', color: '#FFF',
                    my: 1
                }}  >للتواصل</Typography>
                <Divider sx={{ backgroundColor: '#FFA53B', borderWidth: '1px', width: '20%' }} />
                <Box
                    sx={{
                        gap: 1,
                        display: "flex",
                        flexDirection: "column",
                        // padding: "20px",
                        my: 1
                    }}>
                    <Box sx={{ display: 'flex', gap: 2 }} >
                        <LocationOnIcon sx={{ color: '#FFF' }} />
                        <Typography sx={{ color: '#FFF' }}  >السعودية</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }} >
                        <PhoneIphoneIcon sx={{ color: '#FFF' }} />
                        <a href="tel:+966534364350" style={{ textDecoration: 'none', color: '#FFF' }} >
                            <Typography>+966534364350</Typography>
                        </a>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default ContactData
