import React from 'react'
import Title from '../glopal/Title'
import { Box, Container, Grid } from '@mui/material'
import CardPart from './CardPart'
import part1 from './../../assets/Ellipse 13.png'
import part2 from './../../assets/testimonial-2-100x100.png'
import serv1 from './../../assets/figure8.png'
import serv2 from './../../assets/service4.png'
import CardServ from './CardServ'
const OurPartners = () => {
    return (
        <div>
            <Container>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12} >
                            <CardServ path={serv2}
                                name={'يناير 4 ,2023 '}
                                desc={'شركة تنظيف موكيت '} />
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <CardServ path={serv1}
                                name={'يناير 4 ,2023 '}
                                desc={'شركة تنظيف موكيت '} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box style={{ padding: '40px 0' }}>
                <Title title={'قالو عنا'} />
                <Container>
                    <Box sx={{ mt: { md: 6, xs: 2 } }} >
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12} >
                                <CardPart path={part1} name={'بهاء يونس'} desc={'تعاملت مع شركات نظافة مختلفة, ولكن شركة كلين لايت اثبتت انها افضل شركة تنظيف  وذلك نظرا لدقة مواعيدهم وخدماتهم المتوفقة.'} />
                            </Grid>
                            <Grid item md={6} xs={12} >
                                <CardPart path={part2} name={'محمد خالد'} desc={'عانيت كثيرا مع شركات النظافة المختلفة الى ان وجدت شركة كلين لايت المحترفة, وذلك نظرا لمعداتهم وفريق العمل المحترف التي تمتلكه المؤسسة'} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </div>
    )
}

export default OurPartners
