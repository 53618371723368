import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import effect from './../../assets/11.png'
const Card = ({ path, name, supName, desc, supDesc }) => {
    return (
        <div>
            <Box position={'relative'} sx={{ display: 'flex', gap: '2%', alignItems: 'flex-start', flexDirection: { md: 'row', xs: 'column' } }} >
                <Box sx={{ position: 'relative', width: { md: '49%', xs: '100%' } }} >
                    <CardMedia
                        sx={{ position: 'absolute' }}
                        component={'img'}
                        src={effect}
                    />
                    <CardMedia
                        component={'img'}
                        style={img}
                        src={path}
                    />
                    <CardMedia
                        sx={{ position: 'absolute', transform: 'rotate(180deg)', bottom: '-1px', width: '101%' }}
                        component={'img'}
                        src={effect}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: { md: '48%', xs: '100%' } }} >
                    <div>
                        <Typography sx={{ color: '#239698', fontWeight: '400', my: 1 }} variant='h4' >
                            {name}
                        </Typography>
                        <Typography sx={{ color: '#B2B4B4', fontWeight: '400', my: 2 }} variant='h5' >
                            {supName}
                        </Typography>
                        <Typography sx={{ color: '#3D3D3D', fontWeight: '500' }} >
                            {desc}
                        </Typography>
                        <br />
                        <br />
                        <Typography sx={{ color: '#3D3D3D', fontWeight: '500' }} >
                            {supDesc}
                        </Typography>
                    </div>
                </Box>
            </Box>
        </div>
    )
}
const img = {
    width: '100%',
    height: '400px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    objectFit: 'cover',
    margin: 'auto'
}
export default Card
