import { Box, Typography } from '@mui/material'
import React from 'react'

const CardContact = ({ children, name, desc }) => {
    return (
        <div>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                padding: '15px',
                boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.05)',
                width: { md: '450px', xs: '100%' },
                height: { md: '100px', xs: '125px' },
                borderRadius: '10px',
                backgroundColor: '#FFF'
            }} >
                <div>
                    {children}
                </div>
                <Box sx={{ mr: 4 }} >
                    <Typography sx={{ fontWeight: '400', color: '#239698', fontSize: { md: '28px', xs: '23px' } }} >{name}</Typography>
                    <Typography sx={{ fontWeight: '400', color: '#7F8284', fontSize: { md: '28px', xs: '23px' } }} >{desc}</Typography>
                </Box>
            </Box>
        </div>
    )
}

export default CardContact
