import { Box } from '@mui/material'
import React from 'react'

function TopNav() {
    return (
        <>
            <Box sx={{ padding: '15px', width: '100%', background: '#239698' }}>
            </Box>
        </>
    )
}

export default TopNav