import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
import logo from './../../assets/logo/Lc.jpg'
import './Nav.css'
import CustomizedMenus from "./CustomizedMenus";


function NavBar() {
    const [drawer, setDrawer] = useState(false);
    return (
        <>
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    // background: 'rgb(180, 227, 245)',
                    background: '#FFF',
                    padding: "10px"
                }}>
                <>
                    <Toolbar disableGutters sx={{ justifyContent: 'space-between' }} >


                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 2,
                                marginInlineEnd: 1,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: 'flex-start'
                            }}>

                            <Box sx={{
                                flexGrow: 1,
                                gap: 4,
                                marginInlineStart: 8,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: 'flex-start',
                            }}>

                                <Link
                                    to="/"
                                    style={{
                                        color: "#000",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    الرئيسية
                                </Link>
                                <Link
                                    to="/about-us"
                                    style={{
                                        color: "#000",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    من نحن
                                </Link>
                                <CustomizedMenus />
                                <Link
                                    to="/contact-us"
                                    style={{
                                        color: "#000",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    اتصل بنا
                                </Link>
                            </Box>

                            <Link
                                to="/"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    marginInlineEnd: '5%'
                                }}
                            >
                                <img src={logo} height={100} width={115} alt="logo" className="nav-logo" ></img>
                            </Link>
                        </Box>
                        <Box sx={{
                            justifyContent: 'end', display: 'flex', gap: { md: '65px', xs: 1 },
                            alignItems: 'center',
                            width: { md: '0', xs: '100%' }
                        }} >
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: { md: "none", xs: "flex" },
                                    justifyContent: "space-between",
                                    width: '100%'
                                }}>
                                <Link
                                    to="/"
                                    style={{
                                        color: "#000",
                                        textDecoration: "none",
                                        marginInlineEnd: '5%'
                                    }}>
                                    <img src={logo} height={70} width={90} alt="logo" className="nav-logo" ></img>
                                </Link>
                                <IconButton onClick={() => setDrawer(true)}>
                                    <MenuIcon sx={{ fontSize: '2rem', color: '#000' }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                </>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
