
import one from './../assets/services/مكافحة خشرات.jpg'
import tow from './../assets/services/منازل.png'
import three from './../assets/services/مجلس.jpg'
import four from './../assets/services/شقق.jpg'
import six from './../assets/services/سجاد.png'
import siven from './../assets/services/قصور.png'
import heaht from './../assets/services/تنظيف خزانات.png'
import nighn from './../assets/services/Placeholder.png'
import tin from './../assets/services/مساجد.png'
import elevin from './../assets/services/فنادق.png'
import tuelv from './../assets/services/مسابح.png'
export const products = [
        {
                id: 1,
                imageUrl: one,
                name: `كلين لايت `,
                supName: `مكافحة حشرات `,
                desc: `الحشرات هي من اكثر الأمور ازعاج في المنازل خاصة وانها تتسبب في نقل وانتشار الامراض بين افراد الاسرة ناهيك عن الشعور السئ الذي تتركه عند الجميع وبالطبع لا احد يريد لذلك ان يحدث, ولهذا يجب الاهتمام برش المبيدات بشكل منتظم وفي مواعيد محددة لقتل الحشرات ولمنعها من دخول المنزل مرة اخري`,
                supDesc: `وننصح عادة ان يتم رش المنزل مرة واحدة كل 3 شهور بالمبيدات لمنع تواجد الحشرات بالمنزل او دخولها من الاساس, فالمبيدات التي نستخدمها لديها القدرة على التسبب في خلل كبير في الجهاز العصبي لدي الحشرات حتي بعد رشها بمدة تصل الى 3 شهور مما يجعل الحشرات تهرب بعيداً عن مصدر الرش, كما ان المبيدات آمنه بشكل كامل على صحة الاسرة بما فيها كبار السن والاطفال فلا داعى للقلق`,
        },
        {
                id: 2,
                imageUrl: tow,
                name: `كلين لايت `,
                supName: ` شركة تنظيف منازل `,
                desc: `-متخصصون في تنظيف المنازل  , نمتلك 20 عام من الخبرة في المجال , لدينا قدر كبير من العلم في مجال التنظيف المتكامل وخاصة تنظيف المنازل.
نمتلك فريق عمل مميز جداً مدرب علي أعلي مستوي ومسلح بأحدث معدات التنظيف الحديثة , ومساحيق التنظيف العالمية المستوردة للتنظير والتطهير المتكامل والقضاء علي كافة أنواع الميكروبات والبكتيريا داخل المنزل بشكل نهائي.
`,
                supDesc: `بأسعار مناسبة للجميع المستهلكين و الشركات و المكاتب نقدم حزمة أسعار تتميز بكونها الأقل فى سوق شركات التنظيف  و نقدم خدمة و جودة عالية تعتبر الأفضل بين شركات التنظيف العميق.`,
        },
        {
                id: 3,
                imageUrl: three,
                name: `كلين لايت `,
                supName: `شركة تنظيف مجالس `,
                desc: `لأن تنظيف المجالس يعتبر عملية صعبة وتحتاج إلي أدوات مخصصة ومواد تنظيف معينة وضرورى تعتمد علي شركة رائدة في عمليات التنظيف.
بنقدم كافة الخدمات المتعلقة بهذا المجال مثل تنظيف الستائر والمفروشات وكل الأثاث الموجود في المجالس.
`,
                supDesc: `نمتلك أفضل المعدات وأدوات التنظيف بأسعار محددة بشكل دقيق جدا وتنافسي.
نجلب لك الراحة النفسية والهدوء في المكان.`,
        },
        {
                id: 4,
                imageUrl: four,
                name: `كلين لايت `,
                supName: `شركة تنظيف شقق `,
                desc: `الكل يعتبر كلين لايت سيرفيس شركة تنظيف شقق رائدة في مجالها وواجهة نحو تحقيق نظافة شاملة وعميقة بفضل خبرتها الواسعة.
فريقها مدرب ومتخصص بخدمة التنظيف.
`,
                supDesc: `يتم استخدام أحدث التقنيات والمعدات نوفر فريق من العمالة الفلبينية والهندية واليبانية مدرب ومحترف.
ونقدم خيارات متعددة لعملائها يمكنهم الاختيار من بينها.`,
        },
        {
                id: 6,
                imageUrl: six,
                name: `كلين لايت`,
                supName: `تنظيف السجاد والستائر`,
                desc: `السجاد والستائر من الأقمشة التي تحتاج إلى عناية خاصة وخاصة الستائر لإنها عادة ما تكون من أنواع أقمشة رقيقة تحتاج إلى مواد تنظيف معينة مخصصة للستائر فقط لا غير من أجل الحصول على نتائج تنظيف جيدة مع الحفاظ على رونق الستائر والأقمشة, أما السجاد فالدينا أيضاً مساحيق مخصصة له تعمل على إذابة الأتربة والبقع والأوساخ العالقة داخل النسيج بشكل نهائي والحفاظ على النسيج من أي تلف.`,
                supDesc: `تنظيف عميق
القضاء على البكتيريا والميكروبات
آمن تماماً على الصحة
أرخص سعر`,
        },
        {
                id: 7,
                imageUrl: siven,
                name: `كلين لايت `,
                supName: `شركة تنظيف قصور `,
                desc: `-تنظيف قصور  يعتمد بدرجة كبيرة على الخبرة و الأجهزة الحديثة فى ادإزالة الأتربة و البقع للحفاظ على رونق و جمال القصر.
خدماتنا ٢٤ ساعة وفي العطلات الرسميه وعند الاتصال نجيب فورا.
متاح شاحنة كبيرة ومجهزة لجميع معدات شركتنا خاصة بالتنفيذ من ماكينة شفط الغبار.
`,
                supDesc: `منظفات ومذيبات عضوية وسريعة المفعول قوية التأثير قادرة علي إزالة أصعب البقع والاتساخات.
أفضل أعمال مهنية مدربة ذات خبرة لسنوات عديدة.
يتميز فريقنا بالدقة والتنظيم والأمانة والاحترام في العمل.
نقوم بتنظيف الشقق والمنازل والفلل والقصور والمدارس وجميع المصالح العامة والخاصة.`,
        },
        {
                id: 8,
                imageUrl: heaht,
                name: `كلين لايت`,
                supName: ` تنظيف وعزل خزانات المياه`,
                desc: `معظم مصادر المياه للاستخدام اليومي تأتي من الخزانات وبما أنها تتكون من خرسانة أسمنتية وبلاستيك وألياف خارجية يحدث بسببها الكثير من الأمراض المرتبطة بالمياه والأمر يتطور إلي الموت.
لذلك يتوفر خدمات سريعة وفعالة في إصلاح تسريب المياه ونساعدك أيضا في استبداله بسهولة.
`,
                supDesc: `لدينا استجابة سريعة ومتخصصين ذوي خبرة ومهارات عالية.
حلول فعالة من حيث التكلفة وكويبة الأجل.
مجموعة متكاملة من حلول العزل لتسريب المياه.
نحقق لك راحة البال وحصولك علي مياه صالحة للاستخدام كلما احتجت لها.`,
        },
        {
                id: 9,
                imageUrl: nighn,
                name: `كلين لايت `,
                supName: `شركة تنظيف فلل `,
                desc: `نعتبر أفضل شركة تنظيف فلل  على الإطلاق حيث الدقة و الجودة و العناية الفائقة و تكنولوجيا التنظيف الحديثة فلا عناء بعد الأن فنحن نمثل صرح عظيم فى تنظيف فلل .
يتم استخدام ألات بخار وشبه البخار والمكانس الكهربائية الحديثة وغسالات الأرضيات.
نقدم أسعار تنافسية لا تقبل المنافسة.
تشمل مجموعة متنوعة من خدمات التنظيف تشمل الآتي :- 
`,
                supDesc: `مكنسة وفرك أرضيات الفلل.
إزالة خيوط العنكبوت.
غسل الجدران والنوافذ.
تنظيف الاسقف وتطهير المطابخ والحمامات وغيرها.
 تقدم لجميع العملاء في أنحاء المملكة العربية السعودية.`,
        },
        {
                id: 10,
                imageUrl: tin,
                name: `كلين لايت `,
                supName: `شركة تنظيف مساجد `,
                desc: `بما إن مساحات المساجد كبيرة فالشركة تقدم أساليب حديثة لها القدرة علي شمول المساحة مهما كان حجمها بالتنظيف المتقن في وقت سريع.`,
                supDesc: `تعتمد الشركة علي أجهزة البخار المنظف التي تحقق تنظيف عميق للموكيت المفروش وأرضيات المساجد.
نبدأ أولا بالمعاينة ووضع برنامج للعمل ومن ثم التنفيذ الذي يكون عادة بين إقامة العشاء وأذان الفجر.
نضع أسعار رخيصة ومتواضعة خصيصا لعملائنا.`,
        },
        {
                id: 11,
                imageUrl: elevin,
                name: `كلين لايت `,
                supName: `شركة تنظيف فنادق `,
                desc: `لاشك أن الفنادق من الأماكن التي يتوافد عليها الالاف بل الملايين وهذا يجعلها في حاجة للتنظيف المستمر لذلك نقوم بعملية التنظيف باستخدام أحدث الالات والمعدات.`,
                supDesc: `وأجود مواد التعقيم المستوردة وتمتلك الشركة خبرة طويلة وممتدة ولديها إدارة قوية تتعامل بأسلوب راقي ومهذب.
نمنحك أعلي جودة بأرخص سعر.`,
        },
        {
                id: 12,
                imageUrl: tuelv,
                name: `كلين لايت `,
                supName: `شركة تنظيف مسابح  `,
                desc: `بما إن المسابح وسيلة للترفيه ومستحبة لكل الفئات بغض النظر عن السن خصوصا في فصل الصيف. لذلك يفضل مراقبة نظافة المسبح وصيانة معداته.`,
                supDesc: `لذلك نقوم بعملية تنظيف دورية للحفاظ علي نظافة المياة لأنها وسيلة أساسية في نقل الأمراض.
نتبع أساليب تعقيم عالية حتي نحمي مستخدمي المسابح من الإصابة بالأمراض الجلدية والحكة والتهيج الجلدي.
نقوم بتنظيف كل أنواع المسابح كل بطريقة تختلف عن الأخري.`,

        }
];
