import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const CardPart = ({ path, name, desc }) => {
    return (
        <>
            <Box sx={{
                boxShadow: "0px 3px 6px rgba(0, 0, 0,0.5%)",
                backgroundColor: '#FFF',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center', padding: 4,
                gap: 2,
                height: { md: '200px', xs: '300px' }
            }} >
                <Box>
                    <CardMedia
                        component={'img'}
                        style={img}
                        src={path}
                        sx={{ marginTop: { md: '-70%', xs: '0' } }}
                    />
                </Box>
                <Box textAlign={'center'} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} >
                    <Typography>{desc}</Typography>
                    <Typography sx={{ color: '#FFA53B', fontWeight: 'bold' }} >{name}</Typography>
                </Box>
            </Box>
        </>
    )
}
const img = {
    width: '125px',
    height: '125px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    objectFit: 'cover',
    // margin: 'auto',

}
export default CardPart
