import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

const Information = () => {
    return (
        <div>
            <Box  >
                <Typography sx={{ fontWeight: 'bold', color: '#FFF', my: 1 }} >كلين لايت لخدمات التنظيف المتكاملة </Typography>
                <Divider sx={{ backgroundColor: '#FFA53B', borderWidth: '1px', width: '15%' }} />
                <Box
                // sx={{ p: '20px' }}
                >
                    <Typography sx={{ color: '#FFF', my: 1 }} >
                        كل خدمات تنظيف المنازل واالشقق والقصور والمجالس والكنب
                        وابادة ورش الحشرات وتنظيف وعزل الخزانات
                    </Typography>
                </Box>
            </Box>
        </div>
    )
}

export default Information
