import { Box, Button, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './cardservices.css'
import icon from './../../assets/Group 923.png'
import { useNavigate } from 'react-router-dom'
const Card = ({ path, name, route, desc }) => {
    const navigate = useNavigate()
    return (
        <div>
            <Box className='box' position={'relative'} sx={{ boxShadow: "0px 3px 6px rgba(0, 0, 0,5%)", borderRadius: '12px', zIndex: 999 }} >
                <CardMedia
                    component={'img'}
                    style={img}
                    src={path}
                    sx={{
                        position: 'relative',
                        zIndex: 99,
                        transition: '0.5s',
                        '&:hover': {
                            transform: 'scale(1.05)'
                        }
                    }}
                />
                <Box>
                    <CardMedia
                        component={'img'}
                        sx={{
                            width: '65px', height: '65px', borderRadius: '50%', margin: 'auto', marginTop: '-35px',
                            transition: '0.5s',
                            zIndex: 9999,
                            position: 'relative',

                            '&:hover': {
                                transform: 'rotate(-360deg)'
                            }
                        }}
                        src={icon}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2, padding: 2 }} >
                    <Typography variant='h5' sx={{ color: '#239698' }}>{name}</Typography>
                    <Typography>{desc}</Typography>
                    <Button onClick={() => navigate(route)} sx={{
                        color: '#000', backgroundColor: '#FFA53B',
                        textAlign: 'center',
                        display: 'flex',
                        margin: 'auto',
                        '&:hover': {
                            backgroundColor: '#239698',
                            color: '#FFF'
                        }
                    }} >المزيد</Button>
                </Box>
            </Box>
        </div>
    )
}
const img = {
    width: '100%',
    height: '250px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    objectFit: 'cover',
    margin: 'auto'
}
export default Card
